import axios from '@axios'
import { computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { can } from '@core/libs/acl/utils'
import { permissionSubjects } from '@/libs/acl/constants'

export default function useProgramsSetting() {
  // Use toast
  const toast = useToast()
  const canDeleteProgram = computed(() => can('delete', permissionSubjects.Program))
  const canUpdateGeneralProgramSettings = computed(() => can('update', permissionSubjects.GeneralProgramSettings))
  const canUpdateRegistrationChildProgramSettings = computed(() => can('update', permissionSubjects.RegistrationChildProgramSettings))
  const canUpdateRegistrationStaffProgramSettings = computed(() => can('update', permissionSubjects.RegistrationStaffProgramSettings))
  const canUpdateProgramTasks = computed(() => can('update', permissionSubjects.ProgramTasks))
  const canUpdateProgramFees = computed(() => can('update', permissionSubjects.ProgramFees))
  const canUpdateProgramWallets = computed(() => can('update', permissionSubjects.ProgramWallets))
  const canUpdateProgramTags = computed(() => can('update', permissionSubjects.ProgramTags))

  const formBuilderFieldTypes = {
    // STEP: 'step',
    PLAIN_TEXT: 'plain_text',
    SHORT_TEXT: 'short_text',
    LONG_TEXT: 'long_text',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE: 'phone',
    DATE: 'date',
    DOB: 'dob',
    DROPDOWN: 'dropdown',
    MULTI_SELECT: 'multi_select',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio_button',
    FILE_UPLOAD: 'file_upload',
    GROUP_SELECTION: 'group_selection',
    SESSION_SELECTION: 'session_selection',
    PAYMENT_FIELD: 'payment_field',
    SIGNATURE: 'signature',
  }

  const postProgram = queryParams => axios
    .post('/auth/programs', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New program item has posted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updProgram = queryParams => axios
    .put(`/auth/programs/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Program was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating program',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForms = () => axios
    .get('/auth/forms')
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      return response.status
    })

  const fetchFollowing = () => axios
    .get('/auth/attribute-handlers')
    .then(response => response.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching data',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const postPaymentPlan = queryParams => axios
    .post('/auth/payment-plans', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting payment plan',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updatePaymentPlan = queryParams => axios
    .put(`/auth/payment-plans/${queryParams.id}`, queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating payment plan',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postCommunicationSetting = queryParams => axios
    .post('/auth/mail-by-events', queryParams)

  const saveCommunicationSetting = queryParams => axios
    .post('/auth/save-mail-by-events', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Communication Settings was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error saving Communication Settings',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateCommunicationSetting = queryParams => axios
    .put(`/auth/mail-by-events/${queryParams.id}`, queryParams)

  const deleteCommunicationSetting = queryParams => axios
    .delete(`/auth/mail-by-events/${queryParams.id}`)

  const updatePaymentContract = ({ programId, paymentContractId }) => axios
    .put(`/auth/programs/${programId}/add/${paymentContractId}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating payment contract',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const cloneProgram = ID => axios
    .get(`auth/programs/${ID}/clone`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success cloning program',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error cloning program',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const deleteProgram = ID => axios
    .delete(`auth/programs/${ID}`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting program',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting program',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const exportCampers = queryParams => axios
    .get('/auth/campers/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Students',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportStaff = queryParams => axios
    .get('/auth/staffs/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Staff',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const getProgramFiles = ID => axios
    .get(`auth/programs/${ID}/files`)
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting program files',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchZipFiles = queryParams => axios
    .get(`auth/programs/${queryParams.program_id}/download-zip`, {
      params: queryParams.file_names,
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error downloading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    postProgram,
    updProgram,
    fetchProgram,
    fetchForms,
    postCommunicationSetting,
    updateCommunicationSetting,
    deleteCommunicationSetting,
    saveCommunicationSetting,
    postPaymentPlan,
    updatePaymentPlan,
    formBuilderFieldTypes,
    fetchFollowing,
    updatePaymentContract,
    cloneProgram,
    deleteProgram,
    exportCampers,
    exportStaff,
    getProgramFiles,
    fetchZipFiles,
    canDeleteProgram,
    canUpdateGeneralProgramSettings,
    canUpdateRegistrationChildProgramSettings,
    canUpdateRegistrationStaffProgramSettings,
    canUpdateProgramTasks,
    canUpdateProgramFees,
    canUpdateProgramWallets,
    canUpdateProgramTags,
  }
}
